/* styles.css */

/* General Styles */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa; /* Light background color */
}

header {
    background-color: #0d6efd; /* Bootstrap primary color */
    color: white;
    padding: 15px;
    position: relative;
    text-align: center;
}

header h1 {
    font-size: 1.5rem;
    margin: 0;
    padding-left: 120px; /* To accommodate logo and back button */
    padding-right: 70px;  /* To accommodate settings icon */
}

main {
    padding: 20px;
}

section {
    margin-bottom: 20px;
}

button {
    cursor: pointer;
}

input[type="text"],
input[type="password"],
textarea,
select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

label {
    font-size: 16px;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

.form-check-label {
    margin-left: 5px;
}

/* Header Elements */

/* Company Logo */
#company-logo {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 5px;
}

/* Back Button */
#back-button {
    text-decoration: none;
}

#back-button i {
    font-size: 1.5rem;
}

/* Settings Icon */
#settings-icon {
    text-decoration: none;
}

#settings-icon i {
    font-size: 1.5rem;
}

/* Adjustments for Header Elements */
header .position-absolute {
    top: 15px;
}

header #back-button {
    left: 70px;
}

header #company-logo {
    left: 15px;
}

header #settings-icon {
    right: 15px;
}

/* Main Content Headings */
main h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

/* Bottom Navigation Bar */
#bottom-nav {
    display: flex;
}

#bottom-nav .nav-link {
    flex: 1;
}

#bottom-nav .nav-link.active {
    background-color: #495057;
    border-radius: 0;
}

#bottom-nav .nav-link i {
    display: block;
}

#bottom-nav .nav-link div {
    font-size: 0.75rem;
}

a.nav-link:focus,
a.nav-link:hover {
    background-color: #495057;
}

/* Quick Access Buttons (index.html) */
#quick-access .btn {
    font-size: 1rem;
}

#quick-access .btn i {
    font-size: 2rem;
}

#quick-access .btn span {
    font-size: 1rem;
}

/* Safety Snap Report (safety-report.html) */

/* Image Preview */
#image-preview img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
}

/* Voice Note Recording Indicator */
#recording-indicator {
    margin-top: 20px;
    text-align: center;
}

#recording-indicator p {
    font-size: 18px;
    color: #dc3545; /* Bootstrap danger color */
}

/* Voice Notes (voice-notes.html) */

/* Recording Section */
#record-voice-note h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

#start-recording-button {
    width: 100%;
    padding: 15px;
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
}

#stop-recording-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
}

/* Playback Controls */
#playback-controls h2 {
    font-size: 20px;
    margin-top: 30px;
}

#playback-controls audio {
    width: 100%;
    margin-top: 10px;
}

#re-record-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #6c757d; /* Bootstrap secondary color */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
}

/* Attach or Save Section */
#attach-or-save h2 {
    font-size: 20px;
    margin-top: 30px;
}

#attach-or-save .form-check-label {
    font-size: 16px;
}

/* Save Voice Note Button */
#save-voice-note-button {
    width: 100%;
    padding: 15px;
    background-color: #198754; /* Bootstrap success color */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
}

#save-voice-note-button:hover {
    background-color: #157347;
}

/* Work Updates (work-updates.html) */

/* Image Gallery */
#image-gallery h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

#images img {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

#add-image-button {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#add-image-button i {
    font-size: 2rem;
}

/* Summary Length Selection */
#summary-length .btn-group .btn {
    font-size: 1rem;
}

/* Generated Summary Textarea */
#summary-text {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Action Buttons */
#action-buttons button {
    font-size: 1.25rem;
}

/* Asset Scanner (asset-scan.html) */

/* Asset Details */
#asset-details h2,
#update-status h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

#asset-details .list-group-item {
    font-size: 1rem;
}

/* Update Asset Button */
#update-asset-button {
    font-size: 1.25rem;
}

/* History (history.html) */

/* Filters */
#filters .btn-group .btn {
    font-size: 1rem;
}

#filters .btn-group .btn.active {
    background-color: #0d6efd;
    color: white;
}

#filters .btn-group .btn:hover {
    background-color: #0d6efd;
    color: white;
}

/* History Entries */
.history-entry .card {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.history-entry .card-title {
    font-size: 1.1rem;
}

.history-entry .card-text {
    font-size: 0.9rem;
    color: #555;
}

/* Search Bar */
#search-bar {
    margin-bottom: 20px;
}

#search-input {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Settings (settings.html) */

/* Profile Section */
#profile-section h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

#upload-logo {
    font-size: 1rem;
    margin-top: 10px;
}

#logo-preview {
    margin-top: 20px;
    text-align: center;
}

#logo-preview img {
    max-width: 150px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Preferences Section */
#preferences h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

#default-summary {
    font-size: 1rem;
    border-radius: 5px;
}

#notification-settings h3 {
    font-size: 1.1rem;
    margin-top: 20px;
}

#notification-settings .form-check-label {
    font-size: 1rem;
}

/* Save Settings Button */
#save-settings-button {
    font-size: 1.25rem;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
    header h1 {
        font-size: 1.25rem;
        padding-left: 90px; /* Adjust padding for smaller screens */
    }
}